import React from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import TradingViewWidget from './TradingViewWidget';
import FAQAccordionWidget from "./FAQAccordionWidget";



function FAQPage() {
  return (
    <div id="root">
      <div id="top_banner">
        <h3 id="banner_text">Cointraffic-node is 100% free forever and developed by the creators of Pancakeswap ❤️</h3>
      </div>
      <div>
        <Header />
        <div id="body">
          <TradingViewWidget />
          <h2 id="body_title">Questions that you may have</h2>
          <FAQAccordionWidget />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default FAQPage;
