import React from 'react';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-link-container">
        <div id='footer-desc' style={{ flexBasis: '30%' }}>
          <h5 className="list-heading">Cointraffic-node</h5>
          <ul className="uo-list">
            <li><a href="#" style={{ textDecoration: 'none', fontWeight: '500' }}>
              Powering the Decentralized Web, Together. <br /><br />
              Our open-source project is transforming the decentralized web. Cointraffic-node is your one-stop solution for all blockchain-related challenges. Join us and be a part of the future of the web!
            </a></li>
          </ul>
        </div>
        <div style={{ flexBasis: '5%' }}></div>
        <div style={{ flexBasis: '15%' }}>
          <h5 className="list-heading">Developers</h5>
          <ul className="uo-list">
            <li><a href="#">Contributing</a></li>
            <li><a href="#">Github</a></li>
            <li><a href="#">Bug Bounty</a></li>
          </ul>
        </div>
        <div style={{ flexBasis: '15%' }}>
          <h5 className="list-heading">Store</h5>
          <ul className="uo-list">
            <li><a href="#">Merchandize</a></li>
            <li><a href="#">Game</a></li>
            <li><a href="#" target="_blank" rel="noreferrer noopener">Trade</a></li>
          </ul>
        </div>
        <div style={{ flexBasis: '15%' }}>
          <h5 className="list-heading">Support</h5>
          <ul className="uo-list">
            <li><a href="#">Documentation</a></li>
            <li><a href="#" target="_blank" rel="noreferrer noopener">Troubleshooting</a></li>
          </ul>
        </div>
        <div style={{ flexBasis: '15%' }}>
          <h5 className="list-heading">Community</h5>
          <ul className="uo-list">
            <li><a href="#">Accessibility</a></li>
            <li><a href="#">Education</a></li>
          </ul>
        </div>
      </div>
      <p className="copyright">Copyright © 2024 Cointraffic-node. All rights reserved.</p>
    </div>
  );
}

export default Footer;
