import React from 'react';

function Header() {

  return (
    <div id="header">
      <h3 id="brand">Cointraffic-node</h3>
      <div id="header_links_container">
        <h3 className="header_links"><a href="#">Visualize (coming soon)</a></h3>
        <h3 className="header_links"><a href="#">About</a></h3>
        <h3 className="header_links"><a href="https://www.coingecko.com/">Charts</a></h3>
        <w3m-button />

      </div>
    </div>
  );
}

export default Header;
